import * as React from "react";
import { Helmet } from "react-helmet";
import Box from "@mui/material/Box";

import privacyPolicyHtml from "../html/privacy-policy.html";

import * as styles from "./privacy-policy.module.css";
import { Link } from "gatsby";
import { Container } from "@mui/material";

// markup
const IndexPage = ({ data }) => {
  console.log(data);
  return (
    <Box>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Nuff | Privacy Policy</title>
      </Helmet>
      <Container maxWidth="md">
        <span className={styles.header}>
          <Link to="/">
            <h1>Nuff</h1>
          </Link>
        </span>
        <div dangerouslySetInnerHTML={{ __html: privacyPolicyHtml }} />
      </Container>
    </Box>
  );
};

export default IndexPage;
